<div class="banner-area new-docstore">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: 50px;">
                    <h1>Carry documents anywhere hassle free.</h1>
                    <p>
                        This app acts as a digital locker. You can use it to
                        store digital documents. As of now the MVP is released
                        by the Scrrum labs pvt. ltd. SDocstore is directed
                        towards in minimizing the dependence on physical
                        documents.
                    </p>

                    <div class="common-btn-two">
                        <a routerLink="/how-it-works">See how it works</a>
                    </div>

                    <div class="banner-right">
                        <img src="../../../../assets/transparent.webp" alt="Banner" />
                    </div>
                </div>

                <div class="col-6 app-store-new" style="padding-top: 140px;">
                    <h2>Available on:</h2>
                    <div class="app-icons">
                        <div class="col-md-6">
                            <a href="" style="padding-left: 10px;">
                                <img class="apple" style="width: 170px; "
                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                                    alt="Download on the App Store">
                            </a>
                        </div>
                        <div class="col-md-6">
                            <a href=''>
                                <img class="android" style="width: 190px; height: 87px; " alt='Get it on Google Play'
                                    src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70 about-sections">
    <div class="container">
        <div class="section-title">
            <h4>About</h4>
        </div>
        <div class="row align-items-center">
            <div class="col-md-4">
                <div class="about-item">
                    <div class="about-left">
                        <img src="../../../../assets/About section.webp" 
                            alt="About">
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="about-item about-right">
                    <p style="color:#797070">Sdocstore is a secure document locker and storing apps that provides users
                        with a convenient and easy to use platform to store, organize and access their important files.
                        With military grade encryption and advance security measures, Sdocstore guarantees the privacy
                        and protection of users' sensitive information. The app features an intuitive and user friendly
                        interface, making it simple for users to upload, catagories and share their file with ease.
                        Whether it's for personal or professional use, Sdocstore is a perfect solution for anyone who
                        needs to keep their document safe and accesible at anywhere, at any time.</p>
                    <a routerLink="/contact">Contact <i class="icofont-arrow-right"></i></a>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="section-title-two">
        <h4>Features</h4>
    </div>
</div>
<div class="banner-area feature-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">
                    <h3 style="color:#1976D2 ;">Upload any ID</h3>
                    <p style="font-size: 14px; color:#797070">
                        SDocstore enables you to upload any type of your ID to be uploaded and stored. Just select type
                        if document while uploading and enter the id name and you are all good to go.
                    </p>

                    <div class="common-btn-two">

                    </div>

                    <div class="banner-right">
                        <img src="../../../../assets/Features page/feature 1.webp" alt="Banner" />
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70 feature-section">
    <div class="container">

        <div class="row align-items-center" style="margin-top: -20%">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="../../../../assets/Features page/Feature 2 updated (1).webp" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">

                    <h2 class="text" style="color:#1976D2 ;">Upload documents as PDF and Image</h2>
                    <p class="text" style="font-size: 14px;color:#797070">In SDocStore you can upload documents in PDF
                        and image formats only, with a maximum file size of 1MB. This ensures that the app remains
                        lightweight and efficient, while still allowing users to store a significant number of
                        documents.
                        PDF and image formats are widely accepted for documents and ensure that the document can be
                        viewed consistently across different devices and platforms. By limiting the file size to 1MB,
                        SDocStore ensures that the app runs smoothly and quickly, even when dealing with large numbers
                        of documents also ensuring that the app remains fast and reliable.</p>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="banner-area feature-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">

                    <h3 style="color:#1976D2 ;">With the ease of Google sign In.</h3>
                    <p style="font-size: 14px;color:#797070">
                        Sdoctore offers the convenience of Google Sign-In, allowing users to access their accounts with
                        just a few clicks. This feature not only saves time but also provides an added layer of
                        security, as users can trust Google's rigorous authentication process. This app acts as a
                        digital locker.
                    </p>

                    <div class="common-btn-two">

                    </div>

                    <div class="banner-right">
                        <img src="../../../../assets/Features page/Feature 3.webp" alt="Banner" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">

        <div class="row align-items-center" style="margin-top: -20%">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="../../../../assets/Features page/Download feature.webp" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">

                    <h2 class="text" style="color:#1976D2 ;">Download your documents</h2>
                    <p class="text" style="font-size: 14px; color:#797070">SDocStore provides the ability to download
                        documents. This feature is particularly useful for users who need to access their documents
                        offline or share them with others who do not have access to the SDocStore app.
                        To download a document, users simply need to select the document they wish to download and
                        choose the "download" option. The document will then be saved to their device, where it can be
                        accessed and viewed offline.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="banner-area feature-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">

                    <h3 style="color:#1976D2 ;">Share documents.</h3>
                    <p style="font-size: 14px; color:#797070">
                        This feature is particularly useful for individuals who need to share documents with colleagues,
                        clients or for official work. To share a document, users simply need to select the document they
                        wish to share and choose the "share" option. They can then enter the email address of the
                        recipient and set the level of access they wish to grant. For example, they may choose to allow
                        the recipient to view and edit the document, or they may choose to restrict access to viewing
                        only.
                    </p>

                    <div class="common-btn-two">

                    </div>

                    <div class="banner-right">
                        <img src="../../../../assets/Features page/Share feature.webp" alt="Banner" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Explore</h4>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <a class="service-front" routerLink="/business-idea">
                        <img src="../../../../assets/Artwork webp (2)/business.webp">
                        <h3 style="padding-top: 20px;padding-bottom: 20px;">Business idea<a
                                style="margin-left: 50px;color: black;"> → </a></h3>
                    </a>

                    <div class="service-end" style="margin-top: px;">
                        <img src="../../../../assets/Artwork webp (2)/business.webp">

                        <h3 style="padding-top: 20px;padding-bottom: 20px; color: black;">Business idea </h3>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <a class="service-front" routerLink="/how-it-works">
                        <img src="../../../../assets/Artwork webp (2)/how it works.webp">

                        <h3 style="padding-top: 20px;padding-bottom: 20px;">How it works<a
                                style="margin-left: 65px;color: black;"> → </a></h3>

                    </a>

                    <div class="service-end">
                        <img src="../../../../assets/Artwork webp (2)/how it works.webp">

                        <h3 style="padding-top: 20px;padding-bottom: 20px; color: black;">How it works </h3>


                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <a class="service-front" routerLink="/project-ideas">
                        <img src="../../../../assets/projects idea.webp">

                        <h3 style="padding-top: 20px;padding-bottom: 20px;">Project ideas<a
                                style="margin-left: 65px;color: black"> → </a></h3>

                    </a>

                    <div class="service-end">
                        <img src="../../../../assets/projects idea.webp">

                        <h3 style="padding-top: 20px;padding-bottom: 20px; color: black;">Project ideas ></h3>

                        <!-- <a routerLink="/project-ideas">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <a class="service-front" routerLink="/future-possibilities">
                        <img src="../../../../assets/Future_possibilites.webp">
                        <!-- <i class="icofont-tooth"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;">Future possibilties <a
                                style="margin-left: 50px;color: black;">→</a></h3>

                    </a>

                    <div class="service-end">
                        <img src="../../../../assets/Future_possibilites.webp">
                        <!-- <i class="icofont-tooth"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px; color: black;">Future possibilties </h3>

                        <!-- <a routerLink="/future-possibilities">Read More</a> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <-- <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div> --
                </div>
            </div> -->
        </div>
    </div>

</section>


<div class="container">
    <div class="section-title-two">
        <h4>Reviews</h4> <br>
    </div>
</div>
<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape">
                            <img src="../../../../assets/Reviews/Review 1.webp"
                                style="right:84%; margin-right: 180px; border-radius: 30px"
                                alt="Shape">
                        </div>

                        <!-- <h6>Shivam Badoniya</h6> -->
                        <p class="palign" style="color:#797070">I have been using Sdocstore for several months now and I
                            must say, I am thoroughly impressed with its feature.</p>
                        <p class="palign" style="color:#1976D2 ;">Shivam Badoniya.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R2.webp"
                                style="right:84%; margin-right: 180px; border-radius: 30px"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">It is really helpful to be able to have all important
                            documents with you at all times in a very secure place.</p>
                        <p class="palign" style="color:#1976D2 ;">Nadim Akhter.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R3.webp"
                                style="right:84%; margin-right: 180px; border-radius:30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">Excellent. Gets things done easily. Very easy and
                            convenient to use. Now don't have to rely on third party document scanners to store
                            important documents.</p>
                        <p class="palign" style="color:#1976D2 ;">Paramveer Singh.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R4.webp"
                                style="right:84%; margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">Able to use Sdocstore in no network condition & able to
                            access all the issued documents.. thanks for the catching mechanism. Kodos to all people
                            working behind this🙏.</p>
                        <p class="palign" style="color:#1976D2 ;">Kanika Bhatt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R5.webp"
                                style="right:84%; margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">A good option provided by Sdocstore team. To help all
                            its nationals to keep their data online and they need not to carry their all important
                            documents physical.</p>
                        <p class="palign" style="color:#1976D2 ;">Bhawna Malhotra.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R6.webp"
                                style=" right:84%;margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">A nice initiative started by development team, fully
                            digitalized. I appreciate it. We don't need to worry about paper documents, just keep
                            sdocstore with us wherever we are going.</p>
                        <p class="palign" style="color:#1976D2 ;">Sharmila T L.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R7.webp"
                                style=" right:84%;margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">Good easy to use and also help in to store all private
                            document on it. All over the app is satisfactory.</p>
                        <p class="palign" style="color:#1976D2 ;">Abhishek Mittal.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R8.webp"
                                style="right:84%;margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">Very good app. One place to store all your important
                            documents and its very easy to use.</p>
                        <p class="palign" style="color:#1976D2 ;">Vinayak Sharma.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R9.webp"
                                style="right:84%; margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">This can may so useful for every people those are
                            driving vehicles for safe with fines and store our documents here safely.</p>
                        <p class="palign" style="color:#1976D2 ;">Surabhi Verma.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R10.webp"
                                style=" right:84%; margin-right: 180px; border-radius: 30px;"
                                alt="Shape">
                        </div>

                        <p class="palign" style="color:#797070">This app is very good and also protects our documents
                            with very latest technology and good work ..keep up..👍.</p>
                        <p class="palign" style="color:#1976D2 ;">Ankita Verma.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>