
<div class="about-area pt-100 pb-70 about-doc-section">
    <div class="container">
        <div class="section-title">
            <h4 style="margin-top:-100px">About</h4>
        </div>
        <div class="row align-items-center">
            <div class="col-md-4">
                <div class="about-item">
                    <div class="about-left">
                        <img src="../../../../assets/About section.webp"  alt="About">
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="about-item about-right">
                    <!-- <img src="assets/img/home-one/home-bg5.png" alt="About"> -->
                    <!-- <h2>About Us</h2> -->
                    <p style="color:#797070">Sdocstore is a secure document locker and storing apps that provides users with a convenient and easy to use platform to store, organize and access their important files. With military grade encryption and advance security measures, Sdocstore guarantees the privacy and protection of users' sensitive information. The app features an intuitive and user friendly interface, making it simple for users to upload, catagories and share their file with ease. Whether it's for personal or professional use, Sdocstore is a perfect solution for anyone who needs to keep their document safe and accesible at anywhere, at any time.</p>

                    <!-- <ul>
                        <li><i class="icofont-check-circled"></i> Browse Our Website</li>
                        <li><i class="icofont-check-circled"></i> Send Messege</li>
                    </ul> -->
                    <a  routerLink="/contact">Contact <img  src="../../../../assets/contacticon.svg" style="width: 16px;height: auto;"></a>
                </div>
            </div>
        </div>
    </div>
</div>
