import { Component } from '@angular/core';

@Component({
  selector: 'app-app-downloads',
  templateUrl: './app-downloads.component.html',
  styleUrls: ['./app-downloads.component.scss']
})
export class AppDownloadsComponent {

}
