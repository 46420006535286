import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { LoginComponent } from './components/pages/login/login.component';
import { SignupComponent } from './components/pages/signup/signup.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BusinessIdeaComponent } from './components/pages/business-idea/business-idea.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { ExploreComponent } from './components/pages/explore/explore.component';
import { AppDownloadsComponent } from './components/pages/app-downloads/app-downloads.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { ProjectIdeasComponent } from './components/pages/project-ideas/project-ideas.component';
import { FuturePossibilitiesComponent } from './components/pages/future-possibilities/future-possibilities.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'signup', component: SignupComponent},
    {path: 'login', component: LoginComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-condition', component: TermsConditionComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'business-idea', component:BusinessIdeaComponent},
    {path: 'reviews', component:ReviewsComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'explore', component: ExploreComponent},
    {path: 'app-downloads', component: AppDownloadsComponent},
    {path: 'how-it-works', component: HowItWorksComponent},
    {path: 'project-ideas', component: ProjectIdeasComponent},
    {path: 'future-possibilities', component: FuturePossibilitiesComponent},

    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
