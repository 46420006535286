<div class="container">
    <div class="row"  style="justify-content: center;">
        <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s"  style="margin-top:90px">
            <div class="service-item">
                <div class="blog-top">
                    <a><img src="../../../../assets/Artwork webp (2)/how it works.webp" style="margin-top:50px"></a>
                </div>

                <div class="blog-bottom" style="justify-content: center;">
                    <h3 style="padding-top: 20px;padding-bottom: 20px;"><a>How it works</a></h3>
                    <!-- <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p> -->
                </div>
            </div>
        </div>
    </div>
  </div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <p  style="color:#797070;font-size: 14px;">"How Sdoctore Works - Your Ultimate Document Locker & Storing App"</p>
            <p  style="color:#797070;font-size: 14px;">Introduction:
              Sdoctore is a document locker and storing app that provides a secure and convenient way to store, manage, and share documents. With Sdoctore, you can access your documents from anywhere, anytime, and from any device. But, how does Sdoctore work? Let's find out.</p>
        </div>
        <div class="privacy-item">
            <h2>Signing up:</h2>
            <p  style="color:#797070;font-size: 14px;">To use Sdoctore, you need to sign up for an account. You can sign up using your email address and a strong password or you can use Google Sign-In, with just a few clicks. This feature not only saves time but also provides an added layer of security, as users can trust Google's rigorous authentication process.. Once you have signed up, you can start using Sdoctore to store and manage your documents.</p>
            <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose
            </p> -->
        </div>
        <div class="privacy-item">
            <h2>Uploading documents:</h2>
            <p  style="color:#797070;font-size: 14px;">After signing up, you can start uploading your documents to Sdoctore. You can upload documents from your account. Sdoctore supports file formats like PDF's for now up to 1MB in size.</p>
            <!-- <ul>
                <li><i class="icofont-simple-right"></i> Name, email address and/or your photo when you visit our exhibitions and make use of the technologies we offer to you to receive a Good GIF</li>
                <li><i class="icofont-simple-right"></i> Personal data obtained through or generated on our website, newsletters, commercial emails</li>
                <li><i class="icofont-simple-right"></i> IP address</li>
                <li><i class="icofont-simple-right"></i> Your browsing behaviour on the website such as information on your first visit</li>
                <li><i class="icofont-simple-right"></i> Whether you open a newsletter of email and what sections you select</li>
            </ul> -->
        </div>
        <div class="privacy-item">
            <h2>Organizing documents:</h2>
            <p  style="color:#797070;font-size: 14px;">While uploading you can select document type their name to keep them organized and easy access.</p>
        </div>
        <div class="privacy-item">
            <h2>Sharing documents:</h2>
            <p  style="color:#797070;font-size: 14px;">With Sdoctore, you can share your documents with others on different available option in share on the particular devices.
            </p>
            <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose
            </p> -->
        </div>
        <div class="privacy-item">
            <h2>Security features:</h2>
            <p  style="color:#797070;font-size: 14px;">Sdoctore provides advanced security features to keep your documents safe and secure. All documents are stored in encrypted form.</p>
            <!-- <ul>
                <li><i class="icofont-simple-right"></i> Name, email address and/or your photo when you visit our exhibitions and make use of the technologies we offer to you to receive a Good GIF</li>
                <li><i class="icofont-simple-right"></i> Personal data obtained through or generated on our website, newsletters, commercial emails</li>
                <li><i class="icofont-simple-right"></i> IP address</li>
                <li><i class="icofont-simple-right"></i> Your browsing behaviour on the website such as information on your first visit</li>
                <li><i class="icofont-simple-right"></i> Whether you open a newsletter of email and what sections you select</li>
            </ul> -->
        </div>
        <div class="privacy-item">
          <h2>Conclusion:</h2>
          <p  style="color:#797070;font-size: 14px;">Sdoctore is a powerful document safekeeping and storing app that provides a secure and convenient way to store, manage, and share documents. With Sdoctore, you can organize your documents, share them with others, and access them from anywhere, anytime. Sdoctore provides advanced security features, such as encryption, to keep your documents safe and secure. Whether you're a business owner, a healthcare provider, a real estate agent, or a government agency, Sdoctore has something to offer for everyone.</p>
        </div>
    </div>
</section>
