<footer class="pb-70" style="padding-bottom: 22px;">
    <div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center" style="margin-top: 121px;">
                <div class="col-6">
                    <a class="footer-brand" routerLink="/"><img class="img-fluid" src="../../../../assets/sdocstore logo.webp"  style="width: 72px; " alt="Logo"></a>
                    <!-- <h3>Available On</h3>
                    <a href="">
                        <img
                          class="apple" style="width: 149px; "
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                          alt="Download on the App Store">
                      </a>
                      <a href=''>
                        <img
                          class="android" style="width: 190px; "
                          alt='Get it on Google Play'
                          src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                      </a> -->

                    <!-- <div class="newsletter-item">
                        <h2>Join Our Newsletter</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    </div> -->
                </div>

                <div class="col-6" style="padding-left: 22%;" >
                    <a href="" style="padding-left: 10px;">
                        <img
                          class="apple" style="width: 124px; height: 42px; "
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                          alt="Download on the App Store">
                      </a>
                      <a href=''>
                        <img
                          class="android" style="width: 150px;height:60px "
                          alt='Get it on Google Play'
                          src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                      </a>

                </div>
            </div>
        </div>
    </div>

    <!-- <div class="col-sm-6 col-lg-2">
        <div class="footer-item">
            <div class="footer-quick">
                <h3>Quick Links</h3>
                <ul>
                    <li><a routerLink="">About us</a></li>
                    <li><a routerLink="">Blog</a></li>
                    <li><a routerLink="">Our Expertise</a></li>
                    <li><a routerLink="">Faq</a></li>
                    <li><a routerLink="">Doctors</a></li>
                    <li><a routerLink="">Contact us</a></li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:info@opvix.com">info@opvix.com</a>
                                <a href="mailto:hello@opvix.com">hello@opvix.com</a>
                            </li>
                            <li>
                                <i class="icofont-stock-mobile"></i>
                                <a href="tel:+07554332322">Call: +07 554 332 322</a>
                                <a href="tel:+236256256365">Call: +236 256 256 365</a>
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                210-27 Quadra, Market Street, Victoria Canada
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a routerLink="/about">About us</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/blog-details">Our Expertise</a></li>
                            <li><a routerLink="/faq">Faq</a></li>
                            <li><a routerLink="/doctor">Doctors</a></li>
                            <li><a routerLink="/contact">Contact us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Our Services</h3>
                        <ul>
                            <li><a routerLink="/">Dental Care</a></li>
                            <li><a routerLink="/">Cardiology</a></li>
                            <li><a routerLink="/">Hijama Therapy</a></li>
                            <li><a routerLink="/">Massage Therapy</a></li>
                            <li><a routerLink="/">Ambluance Sevices</a></li>
                            <li><a routerLink="/">Medicine</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-feedback">
                        <h3>Feedback</h3>
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Phone">
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" id="your_message" rows="5" placeholder="Message"></textarea>
                            </div>
                            <div class="text-left">
                                <button type="submit" class="btn feedback-btn">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</footer>

<div class="copyright-area" style="margin-top: 0px;">
    <div class="container" style="margin-top: 84px;">
            <div class="row">
                <div class="col-sm-6 col-lg-2" style="padding-top: 47px;">
                    <div class="footer-item">
                        <div class="footer-quick">

                             <!-- <h3>Quick Links</h3> -->
                            <ul >
                                <li><a routerLink="/app-downloads">App downloads</a></li>
                                <li><a routerLink="/features">Features</a></li>
                                <li><a routerLink="/explore">Explore</a></li>
                                <!-- <li><a routerLink="">Business</a></li> -->
                                <li><a routerLink="/reviews">Reviews</a></li>
                                <li><a routerLink="/about">About</a></li>
                                <li><a routerLink="/contact">Contact</a></li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>



            <div class="col-lg-12" >
                <div class="newsletter-item" >
                    <div class="newsletter-form">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="btn newsletter-btn" type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>






         <div class="copyright-area" >
            <div class="container">
                <div class="copyright-item">
                    <p>© SDocstore is Proudly made by <a href="" target="_blank">Scrrum Labs</a></p>
                </div>
            </div>
         </div>

    </div>
</div>

<!-- <-- <div class="container">
<h3>Available On</h3>
<div class="app-icons">
  <a href="">
    <img
      class="apple"
      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
      alt="Download on the App Store">
  </a>
  <a href=''>
    <img
      class="android" style="width: 153px; height: 60%"
      alt='Get it on Google Play'
      src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
  </a>
</div> -->


