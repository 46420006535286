<div class="container">
    <div class="section-title-two">
        <h4>Reviews</h4> <br>
     </div>
   </div>
<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item" style="margin-bottom: 90px;">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape">
                            <img src="../../../../assets/Reviews/Review 1.webp" style=" right:84%;margin-right: 180px; border-radius: 30px" alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">I have been using Sdocstore for several months now and I must say, I am thoroughly impressed with its feature.</p>
                        <p  class="palign"style="color: #1976D2 ;">Shivam Badoniya.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R2.webp"  style=" right:84%;  margin-right: 180px; border-radius:30px" alt="Shape">
                        </div>

                        <p class="palign" style="color:#797070">It is really helpful to be able to have all important documents with you at all times in a very secure place.</p>
                        <p class="palign" style="color: #1976D2 ;">Nadim Akhter.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R3.webp"  style="right:84%; margin-right: 180px; border-radius: 30px;" alt="Shape">
                        </div>

                        <p class="palign" style="color:#797070">Excellent. Gets things done easily. Very easy and convenient to use. Now don't have to rely on third party document scanners to store important documents.</p>
                        <p class="palign" style="color: #1976D2 ;">Paramveer Singh.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R4.webp"  style="right:84%; margin-right:180px; border-radius: 30px; " alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">Able to use Sdocstore in no network condition & able to access all the issued documents.. thanks for the catching mechanism. Kudos to all people working behind this🙏.</p>
                        <p class="palign" style="color: #1976D2 ;">Kanika Bhatt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R5.webp"  style="right:84%; margin-right: 180px; border-radius: 30px;" alt="Shape">
                        </div>
                        <p class="palign" style="color:#797070">A good option provided by Sdocstore team. To help all its nationals to keep their data online and they need not to carry their all important documents physical.</p>
                        <p class="palign" style="color: #1976D2 ;">Bhawna Malhotra.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R6.webp"  style="right:84%;  margin-right: 180px; border-radius:30px;  " alt="Shape">
                        </div>
                        <p class="palign"  style="color:#797070">A nice initiative started by development team, fully digitalized. I appreciate it. We don't need to worry about paper documents, just keep sdocstore with us wherever we are going.</p>
                        <p class="palign" style="color: #1976D2 ;">Sharmila T L.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R7.webp"  style=" right:84%; margin-right: 180px; border-radius: 30px; " alt="Shape">
                        </div>
                        <p class="palign"  style="color:#797070">Good easy to use and also help in to store all private document on it. All over the app is satisfactory.</p>
                        <p  class="palign" style="color: #1976D2 ;">Abhishek Mittal.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text" style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R8.webp"  style="  right:84%;margin-right: 180px; border-radius: 30px; " alt="Shape">
                        </div>
                        <p class="palign"  style="color:#797070">Very good app. One place to store all your important documents and its very easy to use.</p>
                        <p  class="palign" style="color: #1976D2 ;">Vinayak Sharma.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R9.webp"  style="right:84%; margin-right: 180px; border-radius: 30px; " alt="Shape">
                        </div>
                        <p class="palign"  style="color:#797070" >This can may so useful for every people those are driving vehicles for safe with fines and store our documents here safely.</p>
                        <p  class="palign" style="color: #1976D2 ;">Surabhi Verma.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="slider-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text"  style="margin-left: 40%;">
                        <div class="slider-shape-two">
                            <img src="../../../../assets/Reviews/R10.webp"  style=" right:84%; margin-right: 180px ; border-radius: 30px; " alt="Shape">
                        </div>
                        <p class="palign"  style="color:#797070" >This app is very good and also protects our documents with very latest technology and good work ..keep up..👍.</p>
                        <p class="palign" style="color: #1976D2 ;">Ankita Verma.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

