import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';

import { AboutComponent } from './components/pages/about/about.component';

import { ErrorComponent } from './components/pages/error/error.component';

import { ContactComponent } from './components/pages/contact/contact.component';

import { BusinessIdeaComponent } from './components/pages/business-idea/business-idea.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { ProjectIdeasComponent } from './components/pages/project-ideas/project-ideas.component';
import { FuturePossibilitiesComponent } from './components/pages/future-possibilities/future-possibilities.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { ExploreComponent } from './components/pages/explore/explore.component';
import { BusinessComponent } from './components/pages/business/business.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { AppDownloadsComponent } from './components/pages/app-downloads/app-downloads.component';





@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    HomeOneComponent,
    AboutComponent,
    //AppointmentComponent,
    //DepartmentsComponent,
    //TestimonialsComponent,
    //SignupComponent,
    //LoginComponent,
    //PrivacyPolicyComponent,
    //TermsConditionComponent,
    //FaqComponent,
    ErrorComponent,
    //ServicesComponent,
    //ServicesDetailsComponent,
    //DoctorDetailsComponent,
    //DoctorComponent,
    //BlogComponent,
    //BlogDetailsComponent,
    ContactComponent,
    //ComingSoonComponent,
    BusinessIdeaComponent,
    HowItWorksComponent,
    ProjectIdeasComponent,
    FuturePossibilitiesComponent,
    FeaturesComponent,
    ExploreComponent,
    BusinessComponent,
    ReviewsComponent,
    AppDownloadsComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
