<!-- <div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-9 col-lg-9">
                <div class="header-top-item">
                    <div class="header-top-left">
                        <ul>
                            <li><a href="tel:+07554332322"><i class="icofont-ui-call"></i> Call : +07 554 332 322</a></li>
                            <li><a href="mailto:hello@opvix.com"><i class="icofont-ui-message"></i> hello@opvix.com</a>
                            </li>
                            <li><i class="icofont-location-pin"></i> 210-27 Quadra, Canada </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="header-top-item">
                    <div class="header-top-right">
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img class="img-fluid" src="../../../../assets/logo.webp" style="width: 50px;" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img class="img-fluid" src="../../../../assets/logo.webp"  style="width: 70px;" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ">
                        <li class="nav-item ">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>


                        </li>

                        <li class="nav-item"><a routerLink="/features" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Features</a></li>

                        <li class="nav-item">
                            <a routerLink="/explore" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Explore</a>


                        </li>

                        <li class="nav-item">
                            <a routerLink="/reviews" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" >Reviews</a>

                        </li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Doctor</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/doctor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Doctor</a></li>

                                <li class="nav-item"><a routerLink="/doctor-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Doctor Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
<!--
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li> -->
                    </ul>

                    <!-- <div class="nav-srh">
                        <div class="search-toggle">
                            <button class="search-icon icon-search"><i class="icofont-search-1"></i></button>
                            <button class="search-icon icon-close"><i class="icofont-close"></i></button>
                        </div>

                        <div class="search-area">
                            <form>
                                <input type="text" class="src-input" id="search-terms" placeholder="Search here..." />
                                <button type="submit" name="submit" value="Go" class="search-icon"><i class="icofont-search-1"></i></button>
                            </form>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>
