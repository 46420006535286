 <div class="container feature-sectionn">
    <div class="section-title-two">
       <h4>Features</h4>
    </div>
<!-- </div>
   <div class="home-slider owl-theme owl-carousel">
       <div class="slider-item">
           <div class="d-table">
               <div class="d-table-cell">
                   <div class="container">
                       <div class="slider-text">
                           <div class="slider-shape">
                               <img src="../../../../assets/third page.webp" style="margin-top: 64px " alt="Shape">
                           </div>
                           <h1>1.Upload any ID</h1>
                           <p>SDocstore enables you to upload any type of your ID to be uploaded and stored. Just select type if document while uploading and enter the id name and you are all good to go.</p>
                       </div>
                   </div>
               </div>
           </div>
       </div>

       <div class="slider-item">
           <div class="d-table">
               <div class="d-table-cell">
                   <div class="container">
                       <div class="slider-text">
                           <div class="slider-shape-two">
                               <img src="../../../../assets/ezgif.com-gif-maker (2).webp"  style="margin-top: 64px " alt="Shape">
                           </div>
                           <h1>2. With the ease of  Google sign In.</h1>
                           <p>Sdoctore offers the convenience of Google Sign-In, allowing users to access their accounts with just a few clicks. This feature not only saves time but also provides an added layer of security, as users can trust Google's rigorous authentication process.</p>
                       </div>
                   </div>
               </div>
           </div>
       </div>

       <div class="slider-item">
           <div class="d-table">
               <div class="d-table-cell">
                   <div class="container">
                       <div class="slider-text">
                           <div class="slider-shape-three">
                               <img src="../../../../assets/PDF.webp"   style="margin-top: 64px "alt="Shape">
                           </div>
                           <h1>3. Upload in PDF</h1>
                           <p>As of now any document can be uploaded in PDF format maximum 1 mb.</p>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div> -->

   <div class="banner-area feature-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">
                    <h3 style="color:#1976D2 ;">Upload any ID</h3>
                    <p style="font-size: 14px; color:#797070">
                        SDocstore enables you to upload any type of your ID to be uploaded and stored. Just select type if document while uploading and enter the id name and you are all good to go.
                    </p>

                    <div class="common-btn-two">
                        <!-- <a routerLink="/how-it-works">See how it works</a> -->
                        <!-- <a class="cmn-btn-right-two" routerLink="/about"
                            >Learn More</a
                        > -->
                    </div>

                    <div class="banner-right">
                        <img
                            src="../../../../assets/Features page/feature 1.webp"
                            alt="Banner"
                        />
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title">
            <h4 style="margin-top:-100px">About</h4>
        </div> -->
        <div class="row align-items-center" style="margin-top: -20%">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="../../../../assets/Features page/Feature 2 updated (1).webp"  alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <!-- <img src="assets/img/home-one/home-bg5.png" alt="About"> -->
                    <!-- <h2>About Us</h2> -->
                    <h2 class="text" style="color:#1976D2 ;">Upload documents as PDF and Image</h2>
                    <p class="text" style="font-size:  14px; color:#797070">In SDocStore you can upload documents in PDF and image formats only, with a maximum file size of 1MB. This ensures that the app remains lightweight and efficient, while still allowing users to store a significant number of documents.
                        PDF and image formats are widely accepted for documents and ensure that the document can be viewed consistently across different devices and platforms. By limiting the file size to 1MB, SDocStore ensures that the app runs smoothly and quickly, even when dealing with large numbers of documents also ensuring that the app remains fast and reliable.</p>

                    <!-- <ul>
                        <li><i class="icofont-check-circled"></i> Browse Our Website</li>
                        <li><i class="icofont-check-circled"></i> Send Messege</li>
                    </ul> -->
                    <!-- <a routerLink="/contact">Contact</a> -->
                </div>
            </div>
        </div>
    </div>
</div>



<div class="banner-area feature-section">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">

                    <h3 style="color:#1976D2 ;">With the ease of  Google sign In.</h3>
                    <p style="font-size:  14px; color:#797070">
                        Sdoctore offers the convenience of Google Sign-In, allowing users to access their accounts with just a few clicks. This feature not only saves time but also provides an added layer of security, as users can trust Google's rigorous authentication process.  This app acts as a digital locker.
                    </p>

                    <div class="common-btn-two">
                        <!-- <a routerLink="/how-it-works">See how it works</a> -->
                        <!-- <a class="cmn-btn-right-two" routerLink="/about"
                            >Learn More</a
                        > -->
                    </div>

                    <div class="banner-right">
                        <img
                            src="../../../../assets/Features page/Feature 3.webp"
                            alt="Banner"
                        />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title">
            <h4 style="margin-top:-100px">About</h4>
        </div> -->
        <div class="row align-items-center" style="margin-top: -20%">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="../../../../assets/Features page/Download feature.webp"  alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <!-- <img src="assets/img/home-one/home-bg5.png" alt="About"> -->
                    <!-- <h2>About Us</h2> -->
                    <h2 class="text" style="color:#1976D2 ;">Download your documents</h2>
                    <p class="text" style="font-size:  14px; color:#797070">SDocStore provides the ability to download documents. This feature is particularly useful for users who need to access their documents offline or share them with others who do not have access to the SDocStore app.
                        To download a document, users simply need to select the document they wish to download and choose the "download" option. The document will then be saved to their device, where it can be accessed and viewed offline.</p>

                    <!-- <ul>
                        <li><i class="icofont-check-circled"></i> Browse Our Website</li>
                        <li><i class="icofont-check-circled"></i> Send Messege</li>
                    </ul> -->
                    <!-- <a routerLink="/contact">Contact</a> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">

                    <h3 style="color:#1976D2 ;">Share documents.</h3>
                    <p style="font-size:  14px; color:#797070">
                        This feature is particularly useful for  individuals who need to share documents with colleagues, clients or for official work. To share a document, users simply need to select the document they wish to share and choose the "share" option. They can then enter the email address of the recipient and set the level of access they wish to grant. For example, they may choose to allow the recipient to view and edit the document, or they may choose to restrict access to viewing only.
                    </p>

                    <div class="common-btn-two">
                        <!-- <a routerLink="/how-it-works">See how it works</a> -->
                        <!-- <a class="cmn-btn-right-two" routerLink="/about"
                            >Learn More</a
                        > -->
                    </div>

                    <div class="banner-right">
                        <img
                            src="../../../../assets/Features page/Share feature.webp"
                            alt="Banner"
                        />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item" style="margin-top: -56px;">
                    <h3 style="color:#1976D2 ;">4. Secure account with password</h3>
                    <p style="font-size: 18px;">
                       Keep your documents safe and manage them later in your profile
                    </p>

                    <div class="common-btn-two">
                        -- <a routerLink="/how-it-works">See how it works</a> -->
                        <!-- <a class="cmn-btn-right-two" routerLink="/about"
                            >Learn More</a
                        > -->
                    <!-- </div>

                    <div class="banner-right">
                        <img
                            src="../../../../assets/Features page/Feature 4.png" style="height: 500px;"
                            alt="Banner"
                        />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div> -->


