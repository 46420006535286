 <section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h4 style="margin-top: 8px">Explore</h4>
        </div>
        <!-- <div class="section-title">
            <h4>Explore</h4>
        </div> -->

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <a class="service-front" routerLink="/business-idea">
                        <!-- <i class="icofont-doctor"></i> -->
                        <img src="../../../../assets/Artwork webp (2)/business.webp">
                        <h3 style="padding-top: 20px;padding-bottom: 20px;">Business idea <a style="margin-left: 65px;color: black;">→</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                    </a>

                    <div class="service-end" style="margin-top: px;">
                        <img src="../../../../assets/Artwork webp (2)/business.webp">
                        <!-- <i class="icofont-doctor"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;color: black;">Business idea</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                        <a routerLink="/business-idea">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <a class="service-front" routerLink="/how-it-works">
                        <img src="../../../../assets/Artwork webp (2)/how it works.webp">
                        <!-- <i class="icofont-prescription"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;">How it works <a style="margin-left: 65px;color: black;">→</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                    </a>

                    <div class="service-end" style="margin-top: px;">
                        <img src="../../../../assets/Artwork webp (2)/how it works.webp">
                        <!-- <i class="icofont-prescription"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;color: black;">How it works</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                        <a routerLink="/how-it-works">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <a class="service-front"  routerLink="/project-ideas" >
                        <img src="../../../../assets/projects idea.webp">
                        <!-- <i class="icofont-patient-file"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;">Project ideas <a style="margin-left: 65px;color: black;">→</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                    </a>

                    <div class="service-end">
                        <img src="../../../../assets/projects idea.webp">
                        <!-- <i class="icofont-patient-file"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;color: black;">Project ideas</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                        <a routerLink="/project-ideas">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <a class="service-front" routerLink="/future-possibilities">
                        <img src="../../../../assets/Future_possibilites.webp" >
                        <!-- <i class="icofont-tooth"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;" >Future possibilties <a  style="margin-left: 50px;color: black;">→</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                    </a>

                    <div class="service-end">
                        <img src="../../../../assets/Future_possibilites.webp" >
                        <!-- <i class="icofont-tooth"></i> -->
                        <h3 style="padding-top: 20px;padding-bottom: 20px;color: black;">Future possibilties</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> -->
                        <a routerLink="/future-possibilities" >Read More</a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <-- <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div> --
                </div>
            </div> -->
        </div>
    </div>
</section> <br>

