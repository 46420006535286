import { Component } from '@angular/core';

@Component({
  selector: 'app-business-idea',
  templateUrl: './business-idea.component.html',
  styleUrls: ['./business-idea.component.scss']
})
export class BusinessIdeaComponent {

}
