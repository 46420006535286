import { Component } from '@angular/core';

@Component({
  selector: 'app-future-possibilities',
  templateUrl: './future-possibilities.component.html',
  styleUrls: ['./future-possibilities.component.scss']
})
export class FuturePossibilitiesComponent {

}
