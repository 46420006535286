<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item">
                    <h1>Download docstore app.</h1>
                    <p  style="color:#797070">Welcome to the Sdoctore app
                        Sdoctore is the ultimate document locker and storage solution, providing users with a secure and convenient platform to store, organize and access their important files from anywhere, at any time. Our app features military-grade encryption, advanced security measures, and a user-friendly interface, making it the perfect solution for personal or professional use.
                        Here's what you can expect from the Sdoctore app:
                        Easy and secure access to your important files from anywhere, at any time
                        Military-grade encryption to protect your sensitive information
                        User-friendly interface
                        Convenient Google Sign-In option
                        Support for multiple file types
                        Don't wait, download the Sdoctore app today and take control of your digital documents! Available on both the App Store and Google Play Store.</p>

                    <!-- <div class="common-btn-two">
                        <a routerLink="/appointment">Get Appointment</a>
                        <a class="cmn-btn-right-two" routerLink="/about">Learn More</a>
                    </div> -->

                    <div class="banner-right">
                        <img src="../../../../assets/Download (1).webp" style=" margin-top: 100px" alt="Banner">
                        <!-- <img src="assets/img/home-three/2.png" alt="Banner"> -->
                        <!-- <img src="assets/img/home-three/2.png" alt="Banner"> -->
                        <!-- <img src="assets/img/home-one/home-bg5.png" alt="Banner"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="heart-shape"><img src="assets/img/home-three/3.png" alt="Banner"></div> -->
</div>
