import { Component } from '@angular/core';

@Component({
  selector: 'app-project-ideas',
  templateUrl: './project-ideas.component.html',
  styleUrls: ['./project-ideas.component.scss']
})
export class ProjectIdeasComponent {

}
